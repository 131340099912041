import React, { useState } from "react";
import Header from "../components/layout/header";
import Footer from "../components/layout/footer";
import FooterHeadline from "../components/layout/footerheadline";
import Main from "../components/singlepropertypage/single-property-page-main";
import classNames from "classnames";
import { Container } from "reactstrap";
import SideBar from "../components/layout/sidebar/sidebar";
import Topbar from "../components/layout/content/topbar";
import scrollTo from "gatsby-plugin-smoothscroll";
import { useInView } from "react-intersection-observer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { graphql } from "gatsby";
import ProductSEO from "../components/productseo";

const SingleProperty = ({ data }) => {
  const property = data.sanityProperty;
  const [sidebarIsOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
  const { ref, inView, entry } = useInView();

  console.log(property);

  const handleBrightness = () => {
    if (sidebarIsOpen) {
      return "brightness(50%)";
    } else {
      return "brightness(100%)";
    }
  };
  return (
    <div
      style={{
        maxWidth: "100vw",
        width: "100%",
        overflow: "hidden",
        boxSizing: "border-box",
      }}
    >
      <ProductSEO
        title={`${property.propertyname} - Pamelahudson`}
        description={property.propertydescription}
        image={property.propertyimage.asset.url}
        createdAt={property._createdAt}
        updatedAt={property._updatedAt}
      />
      <div className="App wrapper" id="top">
        <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
        <Container
          fluid
          className={classNames("p-0 m-0", { "is-open": sidebarIsOpen })}
          style={{
            filter: `${handleBrightness()}`,
          }}
        >
          <Header>
            {!sidebarIsOpen ? <Topbar toggleSidebar={toggleSidebar} /> : null}
          </Header>
          <div className="margin-class"></div>

          <div
            style={{
              backgroundColor: "#f7f7f7",
            }}
          >
            <Main property={property} />
            <div ref={ref}>
              <div className="mb-5"></div>
              <Footer />
              <div
                style={{
                  borderTop: "1px solid green",
                }}
              >
                <FooterHeadline />
              </div>
            </div>
          </div>
        </Container>
      </div>
      {inView && (
        <div>
          <p
            className="back-to-top in scroll-up-button"
            onClick={() => {
              scrollTo("#top");
            }}
          >
            <FontAwesomeIcon
              className="scroll-up-button-icon mt-2"
              icon={faAngleUp}
            />
          </p>
        </div>
      )}
    </div>
  );
};
export default SingleProperty;

export const query = graphql`
  query ($slug: String!) {
    sanityProperty(slug: { current: { eq: $slug } }) {
      id
      _createdAt
      _updatedAt
      propertyname
      offMarket
      propertyVideoUrl
      propertystatus
      propertyprice
      propertyarea
      propertylocation
      propertybedrooms
      propertybathrooms
      propertydescription
      style
      view
      fullbathrooms
      halfbathrooms
      floors
      fireplace
      heat_cool
      utilities
      laundry
      exterior
      roof
      fencing
      county
      state
      sale_lease
      features
      foundation
      typography
      geolocation {
        lat
        lng
      }
      propertyimage {
        asset {
          title
          url
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            layout: FULL_WIDTH
            formats: WEBP
          )
        }
      }
      gallery {
        asset {
          title
          url
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            layout: FULL_WIDTH
            formats: WEBP
          )
        }
      }
      moreproperties {
        propertyname
        propertyarea
        propertyprice
        slug {
          current
        }
        propertyimage {
          asset {
            title
            gatsbyImageData(
              placeholder: DOMINANT_COLOR
              layout: FULL_WIDTH
              formats: WEBP
            )
          }
        }
      }
    }
  }
`;
