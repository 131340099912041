import React, { useRef, useState } from "react";
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Table,
  Button,
  CardSubtitle,
} from "reactstrap";
import { Col, Row, Form, FormGroup, Label, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExpandAlt,
  faTint,
  faBed,
  faCheckCircle,
  faAngleRight,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { GatsbyImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import { Link } from "gatsby";
import GoogleMapReact from "google-map-react";
import { Icon } from "@iconify/react";
import locationIcon from "@iconify/icons-mdi/map-marker";

const SinglePage = ({ property }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  let slider = useRef(null);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setActiveIndex(next),
  };

  const location = {
    address: `${property.propertyname}, ${property?.propertylocation}`,
    lat: property.geolocation?.lat,
    lng: property.geolocation?.lng,
  };
  return (
    <>
      <div className="container-fluid w-100 Single-page-main-heading">
        <div className="Single-page-main-heading-2 pl-5 ml-4 pt-3">
          <p className="pl-5 ml-5">{property?.propertyname}</p>
        </div>
      </div>
      <div
        classNam="container"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          className="col-10 mt-5 property_container"
          style={{
            maxWidth: "870px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Card className="mb-5 pb-0" style={{ border: "0" }}>
            <div
              style={{ position: "relative", width: "100%", height: "100%" }}
            >
              <button
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "10px",
                  transform: "translateY(-50%)",
                  background: "transparent",
                  border: "0",
                  zIndex: "10",
                }}
                onClick={() => slider.slickPrev()}
              >
                <FontAwesomeIcon
                  style={{
                    color: "white",
                    fontSize: "20px",
                  }}
                  className="icon mr-3"
                  icon={faAngleLeft}
                />
              </button>
              <Slider ref={(c) => (slider = c)} {...settings}>
                {property?.gallery?.map((image, index) => (
                  <GatsbyImage
                    key={index}
                    className="single-page-img"
                    image={image.asset.gatsbyImageData}
                    alt={image.asset.title}
                    objectFit="cover"
                    objectPosition="center"
                  />
                ))}
              </Slider>
              <button
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  background: "transparent",
                  border: "0",
                }}
                onClick={() => slider.slickNext()}
              >
                <FontAwesomeIcon
                  className="icon mr-3"
                  style={{
                    color: "white",
                    fontSize: "20px",
                  }}
                  icon={faAngleRight}
                />
              </button>
            </div>
            <CardBody className="single-page-card-body">
              <CardTitle className="single-page-card-heading" tag="h5">
                {property?.propertyname}, {property?.propertylocation}
              </CardTitle>
              <div className="single-page-btn">
                <Button className="single-page-btn-1">
                  {property?.propertystatus}
                </Button>
                <Button className="ml-1 single-page-btn-2">
                  $
                  {property?.propertyprice
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Button>
              </div>
            </CardBody>
            <div className="container-fluid single-page-rooms-list">
              <div className="row">
                <div className="col-4 pt-2 pb-2" style={{ fontSize: "12px" }}>
                  <FontAwesomeIcon
                    className="property_icon mr-3"
                    icon={faExpandAlt}
                  />
                  {property?.propertyarea} Sq Ft
                </div>
                <div className="col-4 pt-2 pb-2" style={{ fontSize: "12px" }}>
                  <FontAwesomeIcon
                    className="property_icon mr-3"
                    icon={faBed}
                  />
                  {property?.propertybedrooms} Bedrooms
                </div>
                <div className="col-4 pt-2 pb-2" style={{ fontSize: "12px" }}>
                  <FontAwesomeIcon
                    className="property_icon mr-3"
                    icon={faTint}
                  />
                  {property?.propertybathrooms} Bathrooms
                </div>
              </div>
            </div>
            <div
              className="col-sm-12 col-lg-10 mt-5"
              style={{
                // maxWidth: "1000px",
                paddingLeft: "20px",
                paddingRight: "20px",
                marginTop: "40px",
                marginBottom: "40px",
              }}
            >
              {property?.propertydescription}
            </div>
            <CardBody style={{ paddingLeft: "20px" }}>
              <CardTitle className="single-page-card-heading" tag="h5">
                Details
              </CardTitle>
            </CardBody>
            <div
              className="row"
              style={{
                padding: "0 40px",
              }}
            >
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Table>
                  <tbody>
                    <tr>
                      <th className="single-page-th" scope="row">
                        STYLE:
                      </th>
                      <td className="single-page-td">{property?.style}</td>
                    </tr>
                    {property?.view && (
                      <tr>
                        <th className="single-page-th" scope="row">
                          VIEW:
                        </th>
                        <td className="single-page-td">{property?.view}</td>
                      </tr>
                    )}
                    <tr>
                      <th className="single-page-th" scope="row">
                        BEDROOMS:
                      </th>
                      <td className="single-page-td">
                        {property?.propertybedrooms}
                      </td>
                    </tr>
                    <tr>
                      <th className="single-page-th" scope="row">
                        FULL BATHROOMS:
                      </th>
                      <td className="single-page-td">
                        {property?.fullbathrooms}
                      </td>
                    </tr>
                    {property?.halfbathrooms && (
                      <tr>
                        <th className="single-page-th" scope="row">
                          HALF BATHROOMS:
                        </th>
                        <td className="single-page-td">
                          {property?.halfbathrooms}
                        </td>
                      </tr>
                    )}
                    {property?.fireplace && (
                      <tr>
                        <th className="single-page-th" scope="row">
                          FIREPLACE:
                        </th>
                        <td className="single-page-td">
                          {property?.fireplace}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <th className="single-page-th" scope="row">
                        HEAT/COOL:
                      </th>
                      <td className="single-page-td">{property?.heat_cool}</td>
                    </tr>
                    {property?.sale_lease && (
                      <tr>
                        <th className="single-page-th" scope="row">
                          SALE/LEASE:
                        </th>
                        <td className="single-page-td">
                          {property?.sale_lease}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <Table>
                  <tbody>
                    {property?.utilities.length > 0 && (
                      <tr>
                        <th className="single-page-th" scope="row">
                          UTILITIES:
                        </th>
                        <td
                          className="single-page-td"
                          style={{ width: "256.39px" }}
                        >
                          {property?.utilities.join(" ")}
                        </td>
                      </tr>
                    )}
                    {property?.laundry && (
                      <tr>
                        <th className="single-page-th" scope="row">
                          LAUNDRY:
                        </th>
                        <td className="single-page-td">{property?.laundry}</td>
                      </tr>
                    )}
                    {property?.exterior && (
                      <tr>
                        <th className="single-page-th" scope="row">
                          EXTERIOR:
                        </th>
                        <td className="single-page-td">{property?.exterior}</td>
                      </tr>
                    )}
                    {property?.roof && (
                      <tr>
                        <th className="single-page-th" scope="row">
                          ROOF:
                        </th>
                        <td className="single-page-td">{property?.roof}</td>
                      </tr>
                    )}
                    {property?.foundation && (
                      <tr>
                        <th className="single-page-th" scope="row">
                          FOUNDATION:
                        </th>
                        <td className="single-page-td">
                          {property?.foundation}
                        </td>
                      </tr>
                    )}
                    {property?.typography && (
                      <tr>
                        <th className="single-page-th" scope="row">
                          TYPOGRAPHY:
                        </th>
                        <td className="single-page-td">
                          {property?.typography}
                        </td>
                      </tr>
                    )}
                    {property?.fencing && (
                      <tr>
                        <th className="single-page-th" scope="row">
                          FENCING:
                        </th>
                        <td className="single-page-td">{property?.fencing}</td>
                      </tr>
                    )}
                    {property.county && (
                      <tr>
                        <th className="single-page-th" scope="row">
                          COUNTY:
                        </th>
                        <td className="single-page-td">{property?.county}</td>
                      </tr>
                    )}
                    {property?.state && (
                      <tr>
                        <th className="single-page-th" scope="row">
                          STATE:
                        </th>
                        <td className="single-page-td">{property?.state}</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
            <CardBody style={{ paddingLeft: "20px" }}>
              <CardTitle className="single-page-card-heading" tag="h5">
                Features
              </CardTitle>
            </CardBody>
            <div
              className="feature_container"
              style={{
                padding: "0 40px",
              }}
            >
              <Table>
                <tbody style={{ display: "flex", flexWrap: "wrap" }}>
                  {property?.features.map((feature, index) => (
                    // <tr key={index} className="">
                    <td className="single-page-Features-td col-xl-4 col-lg-4 col-md-6 col-sm-12">
                      <div className="table-row">
                        <FontAwesomeIcon
                          className="Features-icon mr-3"
                          icon={faCheckCircle}
                        />
                        {feature}
                      </div>
                    </td>
                    // </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            {property.geolocation && (
              <div className="mt-2 pb-0 mb-0">
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: process.env.GATSBY_MAP_KEY,
                  }}
                  defaultCenter={location}
                  defaultZoom={17}
                >
                  <div className="pin">
                    <Icon
                      icon={locationIcon}
                      style={{
                        width: "30px",
                        height: "30px",
                        color: "#101010",
                      }}
                      className="pin-icon"
                    />
                    <p className="pin-text">{location.address}</p>
                  </div>
                </GoogleMapReact>
                <iframe
                  src=""
                  id="map"
                  frameBorder="0"
                  allowFullScreen=""
                  aria-hidden="false"
                  // tabIndex="0"
                  className="pb-0 mb-0 mt-5"
                  title="Shop-boz-map"
                />
              </div>
            )}
            {property.propertyVideoUrl && (
              <div
                className="w-100 h-100 team_modal_body"
                // style={{ position: "relative", width: "100%" }}
              >
                <iframe
                  style={{ position: "relative" }}
                  title={property.propertyname}
                  src={property.propertyVideoUrl}
                  frameborder="0"
                  //   height="489.38px"
                  //   width="870px"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                  className="video_container no-lazyload"
                ></iframe>
              </div>
            )}
          </Card>
        </div>
        <div
          className="col-2 single-page-second-col mt-5"
          style={{ maxWidth: "80%" }}
        >
          <div className="single-page-second-col-title pl-0 ml-0">
            <p>MORE PROPERTIES</p>
          </div>
          {property?.moreproperties.map((moreproperty, index) => (
            <div key={index}>
              <Card className="p-4 single-page-card">
                <GatsbyImage
                  image={moreproperty?.propertyimage.asset.gatsbyImageData}
                  alt={moreproperty?.propertyimage.asset.title}
                  objectFit="cover"
                  objectPosition="center"
                  className="single-page-card-image"
                />
                <CardBody>
                  <Link
                    to={`/property/${moreproperty.slug.current}`}
                    className="single-page-card-title"
                    tag="h5"
                  >
                    <p>{moreproperty?.propertyname}</p>
                  </Link>
                  <CardSubtitle className="single-page-card-sub-title" tag="h6">
                    $
                    {moreproperty?.propertyprice
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </CardSubtitle>
                </CardBody>
              </Card>
            </div>
          ))}

          <div className="single-page-second-col-title mt-4 pl-0 ml-0">
            <p>MORTGAGE CALCULATOR</p>
          </div>
          <div>
            <Form
              className="About-main-form-input  pb-5"
              style={{
                backgroundColor: "white",
                color: "black",
                width: "270px",
              }}
            >
              <Row form className="container-fluid pt-3">
                <Col md={12} sm={12}>
                  <FormGroup>
                    <Label
                      className="single-page-card-lable"
                      for="exampleEmail"
                    >
                      Sale Price
                    </Label>
                    <Input
                      //   className="Form-i"
                      type="text"
                      name="FirstName"
                      id="FirstName"
                      placeholder="$"
                      style={{ borderRadius: "24px" }}
                    />
                  </FormGroup>
                </Col>
                <Col md={12} sm={12}>
                  <FormGroup>
                    <Label
                      className="single-page-card-lable"
                      for="examplePassword"
                    >
                      Down Payment
                    </Label>
                    <Input
                      type="text"
                      name="LastName"
                      id="LastName"
                      placeholder="$"
                      style={{ borderRadius: "24px" }}
                    />
                  </FormGroup>
                </Col>
                <Col md={12} sm={12}>
                  <FormGroup>
                    <Label
                      className="single-page-card-lable"
                      for="exampleEmail"
                    >
                      Term[Years]
                    </Label>
                    <Input
                      type="mail"
                      name="Email"
                      id="Email"
                      placeholder="Year"
                      style={{ borderRadius: "24px" }}
                    />
                  </FormGroup>
                </Col>
                <Col md={12} sm={12}>
                  <FormGroup>
                    <Label
                      className="single-page-card-lable"
                      for="exampleEmail"
                    >
                      Interest Rate in %
                    </Label>
                    <Input
                      type="mail"
                      name="Email"
                      id="Email"
                      placeholder="%"
                      style={{ borderRadius: "24px" }}
                    />
                  </FormGroup>
                </Col>
                <Col md={12} sm={12}>
                  <FormGroup
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "0.5rem",
                    }}
                  >
                    <Button
                      className="form-btn "
                      style={{
                        color: "#076332",
                        border: "2px solid #076332",
                        width: "100%",
                        textAlign: "center",
                        display: "flex",
                        lineHeight: "auto",
                        justifyContent: "center",
                        textTransform: "capitalize",
                        fontFamily: "Open Sans",
                      }}
                    >
                      Calculate
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SinglePage;
